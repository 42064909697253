<!-- AI测评报告 -->
<template>
    <div class='assess'>
        <img class="me-bg" :src="require('../../assets/task/assess.png')" />
        <div class="box">
            <div class="cupwrap">
                <img class="star1"
                    :src="reportInfo.level>=1?require('../../assets/task/star1.png'):require('../../assets/task/star6.png')" />
                <img class="star2"
                    :src="reportInfo.level>=2?require('../../assets/task/star2.png'):require('../../assets/task/star7.png')" />
                <img class="star3"
                    :src="reportInfo.level>=3?require('../../assets/task/star3.png'):require('../../assets/task/star8.png')" />
                <img class="star4"
                    :src="reportInfo.level>=4?require('../../assets/task/star4.png'):require('../../assets/task/star9.png')" />
                <img class="star5"
                    :src="reportInfo.level>=5?require('../../assets/task/star5.png'):require('../../assets/task/star10.png')" />
                <div class="cup">
                    <img :src="require('../../assets/task/cup.png')" />
                    <div class="grade">{{grade}}</div>
                </div>
            </div>
            <div class="words-board flex-align-evenly">
                <div class="board-item flex-column-center">
                    <span>{{reportInfo.testList.length}}</span>
                    <span>写字数</span>
                </div>
                <div class="center-item flex-column-center"></div>
                <div class="board-item flex-column-center">
                    <span>{{((reportInfo.testList.length)/16*100).toFixed(2)}}%</span>
                    <!-- <span v-else>0%</span> -->
                    <span>总识别率</span>
                </div>
            </div>
            <!-- 选字 -->
            <div class="choose-word">
                <div class="word-item">
                    <span></span>
                    <span>整体测评</span>
                    <span>(点击单字即可查看单字点评)</span>
                </div>
                <div class="word-list">
                    <div class="worditem" :class="{'activeitem':currentIndex==index}" v-for="(item,index) in wordList"
                        :key='index' @click="chooseWord(index)">
                        <div class="word">
                            <el-image style="width: 100%; height: 100%" :src="item.imagePath"></el-image>
                        </div>
                        <div class="starwrap">
                            <el-rate v-model="item.level" disabled></el-rate>
                        </div>
                    </div>
                    <div class="emptyitem" v-for="item in 3" :key="item.id"></div>
                </div>
                <div class="more flex-align-center" @click="more" v-if="allwords.length>12">
                    <span>{{showmore?"收起":"展开更多"}}</span>
                    <i class="el-icon-arrow-down" v-if="!showmore"></i>
                    <i class="el-icon-arrow-up" v-else></i>
                </div>
            </div>
            <!-- 单字点评 -->
            <div class="word-board">
                <div class="word-item">
                    <span></span>
                    <span>单字点评</span>
                </div>
                <div class="single-word-list">
                    <div class="listwrap">
                        <div class="commentitem">
                            <span>原字</span>
                            <img v-if="reportInfo.testList.length>0" :src="reportInfo.testList[currentIndex].wordCover" @click="preview('old')" />
                        </div>
                        <div class="commentitem">
                            <span>待测字</span>
                            <div class="test">
                                <img class="old" :src="cunrrentWord.imagePath" @click="preview('new')" />
                            </div>
                        </div>
                        <div class="commentitem">
                            <span>重影对比</span>
                            <div class="test" @click="preview('compare')" v-if="reportInfo.testList.length>0">
                                <img class="old" :src="reportInfo.testList[currentIndex].wordShadowCover" />
                                <img class="new" :src="cunrrentWord.imagePath" />
                                <img class="tip" :src="reportInfo.testList[currentIndex].wordSkillCover" />
                            </div>
                        </div>
                    </div>
                    <div class="comment">
                        <div class="title">评语</div>
                        <div class="content">
                            {{cunrrentWord.evaluateContent}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 放大图片 -->
        <!-- <van-popup v-model="showPreview">
      <img :src="reportInfo.wordCover" v-if="previewType=='old'" />
      <div class="newwrap">
        <img :src="cunrrentWord.imagePath" v-if="previewType=='new'" />
      </div>
      <div class="compare" v-if="previewType=='compare'">
        <img class="old" :src="reportInfo.wordShadowCover" />
        <img class="new" :src="cunrrentWord.imagePath" />
        <img class="tip" :src="reportInfo.wordSkillCover" />
      </div>
    </van-popup> -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                value: 3,
                photoshow: false,
                showmore: false,
                loading: true,
                wordnum: '', //写字书
                cunrrentWord: {}, //当前选中字
                wordList: [], // 选字列表
                allwords: [], //全部选字
                reportInfo: {
                    scoreAvg: '', //得分
                    writeCount: '', //写字书
                    totalRecognitionRate: '', //总识别率
                    level: '', //星级
                    testList: []
                }, //报告信息
                grade: '',
                currentIndex: 0,
                showPreview: false,
                previewType: ''
            }
        },
        //方法
        methods: {
            goBack() {
                Common.goBack(this);
            },
            more() {
                this.showmore = !this.showmore
                if (this.showmore) {
                    this.wordList = this.allwords
                } else {
                    this.wordList = this.allwords.slice(0, 12)
                }
            },
            // 查询测评报告
            getReport() {
                let data = {
                    uploadId: this.$route.query.id,
                    type: 3
                }
                this.$Api.Myclass.getAssessReport(data)
                    .then(res => {
                        console.log('测评成功', res)
                        this.reportInfo = res.data
                        if (res.level == 5) {
                            this.grade = "优秀"
                        } else if (res.level == 3 || res.level == 4) {
                            this.grade = "良好"
                        } else {
                            this.grade = "待提升"
                        }
                        if (res.data.testList.length > 12) {
                            this.wordList = res.data.testList.slice(0, 12)
                            this.allwords = res.data.testList
                        } else {
                            this.wordList = res.data.testList
                        }
                        this.cunrrentWord = this.wordList[0]
                        this.wordnum = res.data.testList.length
                    })
                    .catch(err => {
                        console.log('测评失败', err)
                    })
            },
            // 选字
            chooseWord(index) {
                this.cunrrentWord = this.wordList[index]
                this.currentIndex = index
            },
            preview(type) {
                this.previewType = type
                this.showPreview = true
            }
        },
        mounted() {
            this.getReport()
        },
    }
</script>
<style lang='less' scoped>
    @import "./less/aireport.less";
</style>